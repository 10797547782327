import { NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages-routing.module';
import { ErrorComponent } from '../components/error/error.component';
import { SharedModule } from '../components/shared.module';
import { SitemapComponent } from './sitemap/sitemap.component';
import { NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { URLComponent } from './url/url.component';
import { UploadModalComponent } from './modal/upload-modal/upload-modal.component';
import { EligibilityModalComponent } from './modal/eligibility-modal/eligibility-modal.component';
import { ConsulateModalComponent } from './modal/consulate-modal/consulate-modal.component';
import { UserScheduleComponent } from './modal/user-schedule/user-schedule.component';
import { DataUpdateComponent } from './modal/data-update/data-update.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { MainMenuComponent } from 'src/app/components/rvm/main-menu/main-menu.component';
import { PageFooterComponent } from '../components/rvm/page-footer/page-footer.component';

@NgModule({
    declarations: [
        ErrorComponent,
        SitemapComponent,
        URLComponent,
        UploadModalComponent,
        EligibilityModalComponent,
        ConsulateModalComponent,
        UserScheduleComponent,
        DataUpdateComponent,
        ContactUsComponent,
        PaymentSuccessComponent
    ],
    imports: [
        CommonModule,
        PagesRoutingModule,
        SharedModule,
        NgbNavModule,
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
        MainMenuComponent,
        PageFooterComponent,
        RouterModule,
        NgbPopoverModule
    ],

    providers: [

    ]
})
export class PagesModule {

}