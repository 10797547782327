<!-- <stp-menu></stp-menu> -->

<!-- <div class="container">
    <div class="row mt-5">
        <div class="col-md-12 text-center">
            <img src="./assets/images/error_image.svg" class="img-fluid" alt="">
        </div>
    </div>
</div> -->
<h1 hidden>404 - Page Not Found</h1>

<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <div class="mt-5">
                <div class="commissionerflair_bold fs-3 text-center">
                    We are sorry, page not found
                </div>
                <img src="./assets/images/404.webp" class="img-fluid" alt="">
                <div class="button_section d-flex justify-content-center">
                    <div class="py-2 px-2 fs-3 commissionerflair_bold yellow_bg_btn pointer" (click)="returnToHome()">
                        Return to Homepage ({{timer}})
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <stp-footer class="position_relative d-block d-md-none mt-5"></stp-footer> -->