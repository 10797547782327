import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';

import { gql } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';

import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GetDataService {
  environmentUrl: string = environment.apiUrl;
  testing: any;
  headers: any;

  token: any;
  // header: any;
  private apolloClient!: ApolloClient<any>;

  constructor(
    private httpLink: HttpLink,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.queryParams.subscribe((params) => {
      this.apolloClient = new ApolloClient({
        link: this.httpLink.create({
          uri: params['preview']
            ? this.environmentUrl + '?preview=true'
            : this.environmentUrl,
        }),
        cache: new InMemoryCache(),
      });
    });
  }

  getHeader() {
    if (isPlatformBrowser(this.platformId)) {
      let token = localStorage.getItem('token');
      if (token != null && token != undefined && token != '') {
        token = JSON.parse(token);

        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',

          // Authorization: `Token ${token}`
          Authorization: `Token ${token}`,
        });
      }
    }
  }
  // getHeader(no_cache?: boolean) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.token = localStorage.getItem('token')
  //       ? localStorage.getItem('token')
  //       : '';

  //     this.header = new HttpHeaders({
  //       Authorization: `Token ${JSON.parse(this.token)}`,
  //       'Cache-Control': 'no-cache',
  //     });
  //   }
  // }

  // getPdf(catid: any) {
  //   return this.http.get(
  //     environment.adminApiUrl +
  //     `/cms/required-doc-pdf/?cat_id=${catid}&type=pdf`,
  //     { responseType: 'blob' }
  //   );
  // }


  getDataWithoutVariable(queryData: any, remvCache?: boolean) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      fetchPolicy: remvCache ? 'no-cache' : 'cache-first',
    });
  }

  getData(queryData: any, endPoint: string, country?: any, remvCache?: boolean) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        country: country,
      },
      fetchPolicy: remvCache ? 'no-cache' : 'cache-first',
    });
  }

  getProductList(queryData: any, product?: any, remvCache?: boolean) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        productid: product,
      },
      fetchPolicy: remvCache ? 'no-cache' : 'cache-first',
    });
  }



  getDataWithVariable(queryData: any, endPoint: string, variable?: any, remvCache?: boolean) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        variable: variable,
      },
      fetchPolicy: remvCache ? 'no-cache' : 'cache-first',
    });
  }

  getDataWithTwoVariable(queryData: any, endPoint: string, v1?: any, v2?: any, remvCache?: boolean) {
    const gqlQuery = gql`
      ${queryData}
    `;

    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        variable1: v1,
        variable2: v2,
      },
      fetchPolicy: remvCache ? 'no-cache' : 'cache-first',
    });
  }

  getSemData(queryData: any, urlPram: string | null) {
    const gqlQuery = gql`
      ${queryData}
    `;

    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        urlParams: urlPram
      },
    });
  }

  getDataWithThreeVariable(
    queryData: any,
    endPoint?: string,
    v1?: any,
    v2?: any,
    v3?: any
  ) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        variable1: v1,
        variable2: v2,
        variable3: v3,
      },
    });
  }

  getDataWithFourVariable(
    queryData: any,
    endPoint?: string,
    v1?: any,
    v2?: any,
    v3?: any,
    v4?: any
  ) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        variable1: v1,
        variable2: v2,
        variable3: v3,
        variable4: v4,
      },
    });
  }

  getDataWithCountry(queryData: any, endPoint: string, countryName?: any) {
    const gqlQuery = gql`
      ${queryData}
    `;

    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        countryName: countryName,
      },
    });
  }

  getDataWithCategoryId(queryData: any, endPoint: string, categoryId?: any) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        categoryId: categoryId,
      },
    });
  }

  getNewsSeo(queryData: any) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
    });
  }

  getSpecificNewsSeo(queryData: any, mediaId: any) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        variable: mediaId,
      },
    });
  }

  getNewsAuthorSeo(queryData: any, authorId: any) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        variable: authorId,
      },
    });
  }

  getBlogsAuthorSeo(queryData: any, authorId: any) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        variable: authorId,
      },
    });
  }

  getBlogSeo(queryData: any) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
    });
  }

  getSpecificBlogSeo(queryData: any, mediaId: any) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        variable: mediaId,
      },
    });
  }

  // getShareUrl(cat_uid: string) {
  //   // https://dev-api.stampthepassport.com/cms/required-doc-pdf/?cat_uid=25d8f722-309b-467f-bc84-1072cd826b34
  //   return this.http.get(
  //     environment.adminApiUrl + `/cms/required-doc-pdf/?cat_uid=${cat_uid}`
  //   );
  // }

  getProdShareUrl(pro_uid: string) {
    // https://dev-api.stampthepassport.com/cms/required-doc-pdf/?cat_uid=25d8f722-309b-467f-bc84-1072cd826b34
    return this.http.get(
      environment.adminApiUrl + `/cms/required-doc-pdf/?pro_uid=${pro_uid}`
    );
  }

  sharePdf(catid: any, mobile: any, type: string) {
    return this.http.get(
      environment.adminApiUrl +
      `/cms/required-doc-url/?${type ? type : ''}=${catid}&phone_no=${mobile}`
    );
  }

  submitInsuranceData(data: any) {
    this.getHeader();
    return this.http.post(
      environment.adminApiUrl + `/insurance-proceed/ `,
      data,
      { headers: this.headers }
    );
  }

  sendFiMoneyLink(data: any) {
    const token = localStorage.getItem('token')
      ? localStorage.getItem('token')
      : '';
    if (token) {
      this.getHeader();
    }

    return this.http.post(
      environment.adminApiUrl + `/send-forex-notification/`,
      data,
      { headers: this.headers }
    );
  }


  // -----------------------------------New apis------------------------ //

  getCountryBannerData(queryData: any, endPoint: string, category?: any) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        category: category,
      },
    });
  }

  getCountryFilterData(queryData: any, endPoint: string, category?: any) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        category: category,
      },
    });
  }

  getSearchData(queryData: any, category?: any, entryType?: any, processingTime?: any, stayDuration?: any, remvCache?: boolean) {
    const gqlQuery = gql`
      ${queryData}
    `;
    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        variable: category,
        variable2: entryType,
        variable3: processingTime,
        variable4: stayDuration,
      },
      fetchPolicy: remvCache ? 'no-cache' : 'cache-first',
    });
  }


}
