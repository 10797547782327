import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { retry } from 'rxjs/operators';
// import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';


@Injectable({
  providedIn: 'root',
})


export class SignInService {
  token: any;
  header: any;

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {


  }


  getHeader() {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      if (this.token) {
        this.header = new HttpHeaders({
          Authorization: `Token ${JSON.parse(this.token)}`,
        });
      }

    }
  }

  getDateLimit(producId: number) {
    this.getHeader();
    return this.http.get(`${environment.adminApiUrl}/date-limit/?product_id=${producId}`, { headers: this.header }).pipe(retry(2));
  }

  getPaxCountUpdationRespons(count: any, productId: any) {
    this.getHeader();
    return this.http
      .get(`${environment.adminApiUrl}/visapro-pax-count/?count=${count}&product_id=${productId}`, {
        headers: this.header,
      })
      .pipe(retry(2));
  }



  postPrimaryCustomer(data: any) {
    this.getHeader()
    return this.http.post(`${environment.adminApiUrl}/primary-customer/`, data, {
      headers: this.header,
    });
  }

  generateOtpForSignUp(data: any) {

    return this.http.post(`${environment.adminApiUrl}/users/customers/send-new-user-otp/`, data).pipe(retry(2));

  }

  verifyNewUser(data: any) {
    return this.http.post(`${environment.adminApiUrl}/users/customers/verify-new-user/`, data).pipe(retry(2));
  }


  getAllPaxData(processId: number) {
    this.getHeader();
    return this.http.get(`${environment.adminApiUrl}/get-all-pax/?primary_customer_id=${processId}`, { headers: this.header }).pipe(retry(2));
  }

  getSinglePaxData(processId: number, passportNo: any, copassenger: boolean) {
    this.getHeader();
    return this.http.get(`${environment.adminApiUrl}/pre-purchase-data-collection/?primary_customer_id=${processId}&signzy_number=${passportNo}&co_traveller=${copassenger}`, { headers: this.header }).pipe(retry(2));
  }

  addCotraveller(data: any) {
    return this.http.post(`${environment.adminApiUrl}/pre-purchase-data-collection/`, data).pipe(retry(2));
  }

  saveTravellerList(data: any) {
    return this.http.post(`${environment.adminApiUrl}/add-to-pax/`, data).pipe(retry(2));
  }

  getPriceDataDateTab(data: any) {
    return this.http.post(`${environment.adminApiUrl}/price-info/`, data);
  }


}
