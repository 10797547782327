import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app/app.constant';
import { GetDataService } from 'src/app/core/services/get-data.service';
import { PaymentSuccessService } from 'src/app/core/services/payment-success.service';
import { VisaProcessService } from 'src/app/core/services/visa-process.service';

declare var webengage: any; // Declare the webengage variable
declare var fbq: any; // Declare the FB pixel variable
@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  @ViewChild("cleintRating", { static: true }) cleintRating!: ElementRef;
  // @ViewChild("cleintRating",{static:true}) cleintRating!:ElementRef;

  applicationNo!: string | null;
  appConstants = new AppConstants();
  ratingStars: string[] = ['star_rate', 'star_rate', 'star_rate', 'star_rate', 'star_rate',];
  curSelectedStar: number = 0;
  isRatingSubmitCmplt: boolean = false;
  feedBack: string = '';
  successDataToShow: any[] = [];
  getByData: any = [];

  constructor(
    public router: Router,
    private activeRoute: ActivatedRoute,
    private getDataService: GetDataService,
    private paysuccService: PaymentSuccessService,
    public visaProService: VisaProcessService,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  ngOnInit(): void {
    this.webengageTriggerSuccess();
    this.getDataFromParams();
    this.sendLink();
    this.open(this.cleintRating);
    this.getSuccessData();
    this.getDetails();
  }
  goToMyBooking() {
    this.router.navigate([this.appConstants.myProfile]);
  }
  getDataFromParams() {
    this.applicationNo =
      this.activeRoute.snapshot.queryParamMap.get('application_no');
  }

  webengageTriggerSuccess() {
    let paymentData = localStorage.getItem('payment_price_details');
    let StrCustomerData = localStorage.getItem('customer_data');
    let pardDataPaymentData;
    let parsedCustomerData;
    if (paymentData) {
      pardDataPaymentData = JSON.parse(paymentData);
    }
    if (StrCustomerData) {
      parsedCustomerData = JSON.parse(StrCustomerData);
    }

    let cumData = { ...pardDataPaymentData, ...parsedCustomerData }
    webengage.track('Payment Sucess', cumData);

    fbq('track', 'Purchase', cumData);
  }

  sendLink() {
    if (isPlatformBrowser(this.platformId)) {
      const url = localStorage.getItem('fiMoneyUrlLink')
        ? localStorage.getItem('fiMoneyUrlLink')
        : '';
      const data = {
        fi_link: url,
      };
      this.getDataService.sendFiMoneyLink(data).subscribe({
        next: (res) => { },
      });
    }
  }

  open(content: any) {
    this.modalService.open(content, { windowClass: 'rating_popup', backdrop: 'static', keyboard: false }).result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log(reason);

      },
    );
  }

  starCtrl(starNumber: number) {
    if (this.curSelectedStar == starNumber) {
      this.curSelectedStar = this.curSelectedStar - 1;
    } else {
      this.curSelectedStar = starNumber;
    }

  }

  getSuccessData() {

    const queryTo = `
      query{
        paymentBenefit{
          id
          heading
          benefits
          tags
          icon
          link
          
        }
      }
    `;

    let removeCache = false;
    this.getDataService.getDataWithoutVariable(queryTo, removeCache).then((res: any) => {
      this.successDataToShow = res?.data?.paymentBenefit;
      console.log(this.successDataToShow);

    }).catch(error => {
      console.log(error);
    });
  }

  submitComplt() {
    let dataToSend = {
      "rating": this.curSelectedStar,
      "feedback": this.feedBack
    }
    this.paysuccService.userRatingSubmission(dataToSend).subscribe({
      next: (result: any) => {
        console.log(result);
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
        this.isRatingSubmitCmplt = true;
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            this.modalService.dismissAll()
          }, 5000);
        }
      }

    });

  }

  getDetails() {
    if (isPlatformBrowser(this.platformId)) {
      let stringDt = localStorage.getItem('dataForSuccPage');
      let parseDt = stringDt ? JSON.parse(stringDt) : '';

      if (parseDt) {
        this.visaProService.getApplicationDetails(parseDt?.cusId, parseDt?.prodId).subscribe(
          (result: any) => {
            this.getByData = result;
          },
          error => {
            console.log(error);
          }
        )
      }
    }


  }
}
