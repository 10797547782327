import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PaymentSuccessService {

  headers: any;
  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    
   
  }

  getHeader() {
    if (isPlatformBrowser(this.platformId)) {
      let token = localStorage.getItem('token');
      if (token != null && token != undefined && token != '') {
        token = JSON.parse(token);
  
        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache' ,
          Authorization: `Token ${token}`
        });
      }  
    }
  }

  // headers = new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   'Authorization': 'Token '
  // });



  // generatePayuKey(data: any): Observable<any> {
  //   this.getHeader();
  //   return this.http.post(`${environment.adminApiUrl}/payu/`, data, { headers: this.headers }).pipe(retry(3));
  // }
  

  userRatingSubmission(data: any): Observable<any> {
    this.getHeader();
    return this.http.post(`${environment.adminApiUrl}/user-feedback/`, data, { headers: this.headers }).pipe(retry(3));
  }
}

