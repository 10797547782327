import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApolloModule } from 'apollo-angular';
import { environment } from 'src/environments/environment';
// import { outsideRoutes } from './page-redirect';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ErrorComponent } from './components/error/error.component';
import { URLComponent } from './pages/url/url.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { AuthGuard } from './core/guard/auth-guard.service';
import { ErrorGuard } from './core/guard/error-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'visa',
    loadChildren: () => import('./pages/page.module').then(m => m.PagesModule),
    canActivate: [ErrorGuard]
  },
  {
    path: 'passport-details',
    loadChildren: () => import('./pages/initial-data-collection/initial-data-collection.module').then(m => m.InitialDataCollectionModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'visa-home',
    loadChildren: () => import('./pages/visa-home/visa-home.module').then(m => m.VisaHomeModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/static/about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/static/footer-faq/footer-faq.module').then(m => m.FooterFaqModule)
  },
  {
    path: 'testimonial',
    loadChildren: () => import('./pages/static/testimonial/testimonial.module').then(m => m.TestimonialModule)
  },
  {
    path: 'assistance',
    loadChildren: () => import('./pages/static/assistance/assistance.module').then(m => m.AssistanceModule)
  },
  {
    path: 'diy-assistance',
    loadChildren: () => import('./pages/static/diy-assistance/diy-assistance.module').then(m => m.DiyAssistanceModule)
  },
  {
    path: 'my-visa',
    loadChildren: () => import('./pages/my-visa/my-visa.module').then(m => m.MyVisaModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'my-bookings',
    loadChildren: () => import('./pages/my-bookings/my-bookings.module').then(m => m.MyBookingsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/static/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule)
  },
  {
    path: 'visa-date',
    loadChildren: () => import('./pages/calendar-selection/calendar-selection.module').then(m => m.CalendarSelectionCarouselModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule)
  },
  {
    path: 'consultant',
    loadChildren: () => import('./pages/consultant/consultant.module').then(m => m.ConsultantModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'author',
    loadChildren: () => import('./pages/author/author.module').then(m => m.AuthorModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/static/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'user-agreement',
    loadChildren: () => import('./pages/static/user-agreement/user-agreement.module').then(m => m.UserAgreementModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./pages/static/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'robots-webtesting.txt',
    loadChildren: () => import('./pages/robots/robots.module').then(m => m.RobotsModule)
  },
  {
    path: 'sitemap-webtesting.xml',
    loadChildren: () => import('./pages/sitemap/sitemap.module').then(m => m.SitemapModule)
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
  },
  {
    path: 'cordelia-visapolicy',
    loadChildren: () => import('./pages/cordelia-visapolicy/cordelia-visapolicy.module').then(m => m.CordeliaVisapolicyModule)
  },
  {
    path: 'justdial-mumbai',
    loadChildren: () => import('./pages/just-dial/just-dial.module').then(m => m.JustDialModule)
  },
  {
    path: 'service/:sem-ini-url/:sem-end-url',
    loadChildren: () => import('./pages/sem/sem.module').then(m => m.SemModule)
  },
  {
    path: 'sem/:url',
    loadChildren: () => import('./pages/sem/sem.module').then(m => m.SemModule)
  },
  {
    path: 'url',
    component: URLComponent,
  },
  {
    path: 'URL',
    component: URLComponent,
  },
  { path: '/', redirectTo: '' },
  { path: 'null', redirectTo: '' },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full',
  },
  // ...outsideRoutes,
  {
    path: '**',
    component: ErrorComponent
  },

  // {
  //   path: '**',
  //   redirectTo: '/',
  // }
];

@NgModule({
  imports: [ApolloModule, RouterModule.forRoot(routes, {
    useHash: environment.useHash,
    //anchorScrolling: 'enabled', initialNavigation: 'enabledBlocking' 
  })],
  // scrollPositionRestoration: 'enabled'
  exports: [RouterModule]
})
export class AppRoutingModule { }
