<stp-main-menu></stp-main-menu>

<div class="get_in_touch">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="heading_h1 mb-0">{{contactDescription?.heading}}</div>
                <img src="./assets/icons/contact_line.webp" class="line_img img-fluid" alt="" loading="lazy">
                <div class="get_in_content">
                    <div class="days_date">{{contactDescription?.subHeading}}</div>
                    <div class="detail_content commissionerflair_medium d-flex align-items-center">
                        <img src="./assets/icons/mail.webp" class="img-fluid" alt="" loading="lazy">
                        <div class="ms-3"><a href="mailto: {{contactDescription?.email}}"
                                target="_blank">{{contactDescription?.email}}</a></div>
                    </div>
                    <div class="detail_content commissionerflair_medium d-flex align-items-center">
                        <img src="./assets/icons/call.webp" class="img-fluid" alt="" loading="lazy">
                        <div class="ms-3"><a href="tel: {{contactDescription?.phoneNo}}"
                                target="_blank">{{contactDescription?.phoneNo}}</a></div>
                    </div>
                    <div class="detail_content commissionerflair_medium d-flex align-items-center">
                        <img src="./assets/icons/whatsapp.webp" class="img-fluid" alt="" loading="lazy">
                        <div class="ms-3">
                            <!-- https://api.whatsapp.com/send/?phone=919076133777&text=Hello&type=phone_number&app_absent=0
                            https://wa.me/919370566863?text=Hello -->
                            <a href="https://api.whatsapp.com/send/?phone=919076133777&text=Hello&type=phone_number&app_absent=0"
                                target="_blank">
                                +91 9076133777
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 address-padding">
                <ng-select class="ngb_bropdown_select" (change)="setAddress()" [(ngModel)]="selectedSideInfo"
                    [multiple]="false" [placeholder]="selectedSideInfo? '' : contactUsSideinfo[0]?.heading">
                    <ng-option class="bg-white" *ngFor="let dropDown of contactUsSideinfo" [value]="dropDown"
                        [disabled]="false">
                        {{dropDown?.heading}}
                    </ng-option>
                </ng-select>
                <div class="selected_content">
                    <div class="day_date">{{selectedSideInfo?.subHeading}}</div>
                    <div class="contact_number"><a
                            href="tel: {{selectedSideInfo?.phoneNo}}">{{selectedSideInfo?.phoneNo}}</a></div>
                    <div class="address">{{selectedSideInfo?.address}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="iframe">
    <iframe id="contactus-map" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<div class="container">
    <div class="row component_separation margin_top_85">
        <div class="col-md-12 text-center">
            <div class="heading_h2">Have something to say? We’re listening!</div>
            <div>Fill in the details below and our team will reach out to you:</div>
        </div>
        <form [formGroup]="contactForm" (ngSubmit)="onContactSubmit(contactForm)" class="contact_form">
            <div class="col-md-10 offset-md-1 mt-md-5">
                <div class="row mb-4">
                    <div class="col-md-2 d-flex align-items-center justify-content-md-end">
                        <label for="fullName" class="form-label font_16 color_20">
                            Full Name
                        </label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control rounded-0" id="fullName" required
                            formControlName="full_name">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-2 d-flex align-items-center justify-content-md-end">
                        <label for="mobile_no" class="form-label font_16 color_20">
                            Mobile No
                        </label>
                    </div>
                    <div class="col-md-10">
                        <input type="number" class="form-control rounded-0" id="mobile" required
                            formControlName="mobile_no">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-2 d-flex align-items-center justify-content-md-end">
                        <label for="email" class="form-label font_16 color_20">
                            Email
                        </label>
                    </div>
                    <div class="col-md-10">
                        <input type="email" class="form-control rounded-0" id="mobile" required formControlName="email">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-10 offset-md-2">
                        <div class="d-flex align-items-center">
                            <div class="checkbox_item">
                                <label for="email" class="form-label font_16 color_20">
                                    Feedback
                                </label>
                                <input type="radio" name="purpose" [value]="'Feedback'" formControlName="purpose">
                            </div>
                            <div class="checkbox_item">
                                <label for="email" name="purpose" class="form-label font_16 color_20">
                                    New Inquiry
                                </label>
                                <input type="radio" name="purpose" [value]="'New Inquiry'" formControlName="purpose">
                            </div>
                            <div class="checkbox_item">
                                <label for="email" class="form-label font_16 color_20">
                                    Help & Support
                                </label>
                                <input type="radio" name="purpose" [value]="'Help & Support'" formControlName="purpose">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10 offset-md-2">
                        <textarea name="message" id="message" class="form-control rounded-0" rows="5"
                            placeholder="Start writing here...." formControlName="message"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button [disabled]="buttonDisable"
                        class="btn btn-primary rounded-0 justify-content-center btn-with-loader mx-auto mt-4"
                        type="submit">
                        <div *ngIf="loaderShow"><img class="img-fluid" src="./assets/images/loading-gif.gif" alt="">
                        </div> Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="row component_separation">
        <div class="col-md-10 offset-md-1 mt-md-5">
            <!-- <stp-hr-vr-tab [componentData]="faqData?.categories" [tabView]="false"></stp-hr-vr-tab> -->
            <stp-vertical-tab [navData]="generalFaq" [faqPageRouting]="true"></stp-vertical-tab>
        </div>
    </div>
</div>

<!-- <div class="loyality_banner">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="heading_h1 heimat_stencil text-white">LOYALTY BANNER HERE</div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="rewarding_loyality">
    <div class="container">
        <div class="row">
            <div class="col-md-3 mb-4 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-start">
                <img src="./assets/images/stamp.webp" class="img-fluid" alt="">
            </div>
            <div class="col-md-7 text-center text-md-start mb-4 mb-md-0">
                <div class="heading_h2">Rewarding your loyalty!</div>
                <div class="color_70">Earn loyalty points with every booking.</div>
            </div>
            <div class="col-md-2 d-flex align-items-center justify-content-center justify-content-md-start">
                <button class="btn btn-primary commissionerflair_semibold read-more rounded-0">APPLY NOW</button>
            </div>
        </div>
    </div>
</div> -->

<stp-page-footer class="position_relative"></stp-page-footer>