import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { retry } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DiyAssistanceService {
  headers: any
  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {

  }


  getHeader() {
    if (isPlatformBrowser(this.platformId)) {
      let token = localStorage.getItem('token');
      if (token != null && token != undefined && token != '') {
        token = JSON.parse(token);

        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`
        });
      }
    }

  }

  userDetailSubmission(data: any) {
    this.getHeader();
    return this.http.post(`${environment.adminApiUrl}/payment-info/`, data, { headers: this.headers }).pipe(retry(2));
  }

  paymentSubmission(data: any) {
    this.getHeader();
    return this.http.post(`${environment.adminApiUrl}/payment-data/`, data, { headers: this.headers }).pipe(retry(2));
  }

  newJourneyPaymentSubmission(data: any) {
    this.getHeader();
    return this.http.post(`${environment.adminApiUrl}/create-payment/`, data, { headers: this.headers }).pipe(retry(2));
  }

  getDateLimit(producId: number) {
    return this.http.get(`${environment.adminApiUrl}/date-limit/?product_id=${producId}`,).pipe(retry(2));
  }

  getLeadFormStatus(producId: any) {
    this.getHeader();
    return this.http.get(`${environment.adminApiUrl}/get-lead-form/?product_id=${producId}`, { headers: this.headers }).pipe(retry(2));
  }

  postCurrentUrl(url: any) {
    this.getHeader();
    return this.http.post(`${environment.adminApiUrl}/lead-collection/live-url-capture/`, url, { headers: this.headers }).pipe(retry(2));
  }

  postLeadFormData(data: any) {
    this.getHeader();
    return this.http.post(`${environment.adminApiUrl}/lead-form/`, data, { headers: this.headers }).pipe(retry(2));
  }

  postCoupon(data: any) {
    this.getHeader();
    return this.http.post(`${environment.adminApiUrl}/arise-coupon/`, data, { headers: this.headers }).pipe(retry(2));
  }

  checkAddOnOrNot(data: any) {
    this.getHeader();
    return this.http.get(`${environment.adminApiUrl}/check-add-on/?country_id=${data?.countryId}&product_id=${data?.productId}`, { headers: this.headers })
  }

  //Start:- revamp

  getAllAddons(data: any) {
    this.getHeader();
    return this.http.get(`${environment.adminApiUrl}/get-addons/?product_id=${data?.productId}`, { headers: this.headers })
  }

  addonPriceInfo(data: any) {
    this.getHeader();
    return this.http.post(`${environment.adminApiUrl}/price-info/`, data, { headers: this.headers }).pipe(retry(2));
  }
  // End:- revamp



}